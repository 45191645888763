import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import Tooltip from "@mui/material/Tooltip";
import Loader from "components/Progress";
import noData from "assets/img/lineChart.png";
import { useGetGroupAggregatedSingleParameterReadingQuery } from "services/analytics";
import TrendWidget from "./TrendWidget";



export default function ESDDataPointTrend(props) {

  let token = window.localStorage.getItem("token");
  const [trendsData, setTrendsData] = useState([]);
  const [aggregatedValue, setAggregatedValue] = useState("");
  const [unit, setUnit] = useState("");
  const [load, setLoad] = useState(false);
  const [intervalRef, setIntervalRef] = useState(null);
  const trendQuery = useGetGroupAggregatedSingleParameterReadingQuery({
    token,
    id: props.id,
    parameters: `?mode=hourly&parameter=${props.datapoint}&actualDataPoint=${props.actualDataPoint}&dateFrom=${new Date(props.startDate).toISOString()}&dateTo=${new Date(props.endDate).toISOString()}${
      (props.service.group && props.service.group.id) ||
      (props.filtersValue.group && props.filtersValue.group.id)
        ? `&groupId=${props.service.group?.id || props.filtersValue.group?.id}`
        : ""
    }`,
  });

  useEffect(() => {
    // console.log({trendQuery})
    if (!trendQuery.isFetching && trendQuery.isSuccess) {
      if (
        trendQuery?.data?.payload &&
        Object.keys(trendQuery.data.payload).length
      ) {
        let readings = trendQuery?.data?.payload?.data[0]?.readings.map((reading) => {
          const value = parseInt(reading.reading);
          const adjustedValue = props.applyMultiplier ? value * props.multiplier : value;
        
          return {
            date: new Date(reading.date).getTime(),
            value: adjustedValue
          }
        })
        setTrendsData(readings);
        // console.log("trendQuery.trendDAta: ", trendsData);

        let averageReading = trendQuery?.data?.payload?.data[0]?.averageReading;
        const adjustedReadingValue = props.applyMultiplier ? averageReading * props.multiplier : averageReading;

        setAggregatedValue(adjustedReadingValue);
        let unit = trendQuery?.data?.payload?.data[0]?.unit;
        let adjustedUnit = props.applyMultiplier ? props.currency : unit;
        setUnit(adjustedUnit);
        setLoad(true);
      } else {
        setTrendsData([]);
        setAggregatedValue("");
        setUnit("");
        setLoad(true);
      }
    }
  }, [trendQuery.isFetching, props.multiplier, props.actualDataPoint]);

  /* useEffect(() => {
    const timerRef = setInterval(() => {
      trendQuery.refetch();
    }, 60000);
    setIntervalRef(timerRef);

    return () => {
      clearInterval(intervalRef);
    };
  }, []); */
  // console.log("trendsData data:" , trendsData);

  return (
    <>
      {!trendQuery.isFetching && load ? 
        !trendQuery.isError && trendsData.length ?
          (<>
            <TrendWidget
                name={props.name}
                data={trendsData}
                aggregatedValue={aggregatedValue}
                unit={unit}
                datapoint={props.label}
                icon={props.icon}
                reading={aggregatedValue}
                color={props.color}
            />
            </>) : !trendsData?.data?.success ? (
            <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Tooltip title={props.label} placement="top" arrow>
              <div style={{ 
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: "#979797",
                  padding: "0px 9px 0px 11px",
                  maxWidth: "242px" }}>
                  {props.label}
              </div>
            </Tooltip>
            <img src={noData} height="80px" width="80px" />
            <p style={{ color: "#c7c7c7", marginBottom: "15px" }}>
              No data found
            </p>
          </div>
          ) : null :
        (
          <div style={{width: "50%", float: "left", textAlign: "center", justifyContent: "center", marginTop: "3.2%"}}>
          <Loader />
          </div>
        )
      }
    </>
   /*  <>
        <div>{props.datapoint}</div>
        <div
        style={{
            overflow: "hidden",
            // paddingBottom: "56.25%",
            // position: "relative",
            height: "auto",
        }}
        >
        <div
            id={props.name}
            style={{
            position: "absolute",
            left: "0",
            top: "26px",
            height: "100%",
            width: "100%",
            }}
        ></div>
        </div>
    </> */
  )

}